<template>
	<div>
		<div class="margin-top-20">
			<ul v-if="true">
				<li
					v-for="item of customerPrices"
					:key="item.stock_id"
					class="list-item"
				>
					<b-button
						class="is-primary small-btn"
						outlined
						size="is-small"
						@click="onEdit(item)"
					>
						Edit
					</b-button>
					&nbsp;-&nbsp;
					<b-button
						class="is-primary small-btn"
						outlined
						size="is-small"
						@click="onDelete(item.stock_id)"
					>
						Delete
					</b-button>
					&nbsp;&nbsp;<b>{{ item.stock_id }}</b> &nbsp;-&nbsp;
					{{ item.stock.stock_name }} &nbsp;-&nbsp;
					{{
						String(parseFloat(item.stock.stock_price) / 100).replace('.', ',')
					}}
					&nbsp;kr.&nbsp;/&nbsp; Customer Special Price:
					{{ String(parseFloat(item.customers_price) / 100).replace('.', ',') }}
					&nbsp;kr.
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import Store from '@/store'
import { reactive, ref } from '@vue/composition-api'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import GetCustomerPriceListQuery from '@/graphql/queries/customer/customerPriceList/getCustomerPriceList.query.gql'
import DeleteCustomersPriceMutation from '@/graphql/mutations/customer/customerPriceList/deleteCustomersPrice.mutation.gql'
// import EditCustomersPriceMutation from '@/graphql/mutations/customer/customerPriceList/updateCustomerPrice.mutation.gql'

export default {
	name: 'PriceListLines',
	setup() {
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result } = useQuery(
			GetCustomerPriceListQuery,
			() => ({
				company_id: Store.getters.getPLCurrentCompany,
				customer_id: Store.getters.getPLSelectedCustomerID,
			}),
			options,
		)
		const customerPrices = useResult(
			result,
			null,
			(data) => data.customer_price_list,
		)
		const onEdit = (item) => {
			Store.dispatch('setPLStockItemID', item.stock_id)
			Store.dispatch('setPLSearchStockItemShow', false)
			Store.dispatch('setPLCustomersPriceShow', true)
			//**************************************************************
			Store.dispatch('setPLStockItemName', item.stock.stock_name)
			Store.dispatch('setPLStockItemPrice', item.stock.stock_price / 100)
			Store.dispatch('setPLCustomersPrice', item.customers_price / 100)
		}
		const { mutate: deleteCustomerPrice, onDone } = useMutation(
			DeleteCustomersPriceMutation,
			() => ({
				variables: {
					company_id: Store.getters.getPLCurrentCompany,
					customer_id: Store.getters.getPLSelectedCustomerID,
					stock_id: PLStockID.value,
				},
			}),
		)
		const PLStockID = ref('')
		const onDelete = (stockID) => {
			PLStockID.value = stockID
			console.log('stockID', stockID)
			deleteCustomerPrice()
		}
		onDone(() => {
			Store.dispatch(
				'setPLCustomersPriceLinesKey',
				Store.getters.getPLCustomersPriceLinesKey + 1,
			)
			console.log('DONE-2!!!!!!!')
		})
		return {
			customerPrices,
			onEdit,
			onDelete,
		}
	},
}
</script>

<style scoped></style>
