var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('b-button',{staticClass:"is-primary margin-bottom-20",on:{"click":_vm.onClose}},[_vm._v("X")])],1),_c('section',[(_vm.$store.getters.getPLSearchBoxShow)?_c('div',{key:_vm.$store.getters.getPLSearchBoxKey},[_c('span',{staticClass:"tag is-primary is-medium"},[_vm._v("SELECT CUSTOMER")]),_c('form',{attrs:{"id":"formPLSelectCustomer"}},[_c('vue-form-generator',{attrs:{"schema":_vm.schemaPriceListSelectCustomer,"model":_vm.modelPriceListSelectCustomer,"options":_vm.formOptionsPriceListSelectCustomer}})],1)]):_vm._e(),(
				_vm.modelPriceListSelectCustomer.searchStringCustomer === '' &&
				_vm.$store.getters.getPLSearchResultsShow
			)?_c('div',{staticClass:"list-item"},[_c('b',[_vm._v("Search Results")])]):_vm._e(),(
				_vm.modelPriceListSelectCustomer.searchStringCustomer === '' &&
				_vm.$store.getters.getPLSearchResultsShow
			)?_c('div',[_vm._v(" Nothing to show please enter search criteria! ")]):_vm._e(),_c('div',[(
					_vm.modelPriceListSelectCustomer.searchStringCustomer !== '' &&
					_vm.$store.getters.getPLSearchResultsShow
				)?_c('ul',_vm._l((_vm.searchedCustomers),function(item){return _c('li',{key:item.customer_id,staticClass:"list-item"},[_c('b-button',{staticClass:"is-primary small-btn",attrs:{"outlined":"","size":"is-small"},on:{"click":function($event){return _vm.onSelect(
								item.customer_id,
								item.customer_nickname,
								item.customer_title
							)}}},[_vm._v(" Select ")]),_c('b',[_vm._v(_vm._s(item.customer_id))]),_vm._v("  -  "+_vm._s(item.customer_nickname)+"  -  "+_vm._s(item.customer_org_num)+"  -  "),_vm._m(0,true),_vm._v(" "+_vm._s(item.customer_discount_percent)+"%     - "),_c('span',{staticClass:"alarm"},[_c('b',[_vm._v(_vm._s(item.customer_title))])])],1)}),0):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"store"},[_c('b',[_vm._v("Discount:")])])}]

export { render, staticRenderFns }