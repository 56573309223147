<template>
	<div>
		<div v-if="$store.getters.getPLPriceListHeadersShow === true">
			<div>
				<span class="tag is-primary is-medium">CUSTOMER INFO</span>
			</div>
			Selected Customer:
			<strong>{{ $store.getters.getPLSelectedCustomerID }} </strong> -
			<strong>{{ $store.getters.getPLSelectedCustomerNickname }} </strong> -
			<strong>{{ $store.getters.getPLSelectedCustomerTitle }} </strong><br />
			Our Company:
			<strong>{{ $store.getters.getPLCurrentCompany }}</strong>
			<div v-if="$store.getters.getPLChangeCustomerButtonShow === true">
				<b-button
					class="is-primary margin-top-15 margin-bottom-15"
					@click="onChangeCustomer"
					>Change the customer</b-button
				>
			</div>
			<div v-if="$store.getters.getPLChangeCustomerButtonShow === true">
				<b-button
					class="is-success margin-top-15 margin-bottom-15"
					@click="onContinue"
					>Continue</b-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import Store from '@/store'

export default {
	name: 'priceListHeaders',
	setup() {
		const onChangeCustomer = () => {
			Store.dispatch('setPLSearchBoxShow', true)
			Store.dispatch('setPLSearchResultsShow', true)
			Store.dispatch('setPLPriceListHeadersShow', false)
			Store.dispatch('setPLChangeCustomerButtonShow', false)
		}
		const onContinue = () => {
			Store.dispatch('setPLSearchBoxShow', false)
			Store.dispatch('setPLSearchResultsShow', false)
			Store.dispatch('setPLPriceListHeadersShow', true)
			Store.dispatch('setPLChangeCustomerButtonShow', false)
			Store.dispatch('setPLSearchStockItemShow', true)
			Store.dispatch('setPLCustomersPriceLinesShow', true)
		}
		const onDefault = () => {
			// Store.dispatch('setShowSelectSearchForm', true)
			return
		}
		onDefault()
		return {
			onChangeCustomer,
			onContinue,
		}
	},
}
</script>

<style scoped></style>
