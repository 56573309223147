<template>
	<div>
		<div>
			<b-button class="is-primary margin-bottom-20" @click="onClose"
				>X</b-button
			>
		</div>
		<section>
			<div
				:key="$store.getters.getPLSearchBoxKey"
				v-if="$store.getters.getPLSearchBoxShow"
			>
				<span class="tag is-primary is-medium">SELECT CUSTOMER</span>
				<form id="formPLSelectCustomer">
					<vue-form-generator
						:schema="schemaPriceListSelectCustomer"
						:model="modelPriceListSelectCustomer"
						:options="formOptionsPriceListSelectCustomer"
					>
					</vue-form-generator>
				</form>
			</div>
			<div
				class="list-item"
				v-if="
					modelPriceListSelectCustomer.searchStringCustomer === '' &&
					$store.getters.getPLSearchResultsShow
				"
			>
				<b>Search Results</b>
			</div>
			<div
				v-if="
					modelPriceListSelectCustomer.searchStringCustomer === '' &&
					$store.getters.getPLSearchResultsShow
				"
			>
				Nothing to show please enter search criteria!
			</div>
			<div>
				<ul
					v-if="
						modelPriceListSelectCustomer.searchStringCustomer !== '' &&
						$store.getters.getPLSearchResultsShow
					"
				>
					<li
						v-for="item of searchedCustomers"
						:key="item.customer_id"
						class="list-item"
					>
						<b-button
							class="is-primary small-btn"
							outlined
							size="is-small"
							@click="
								onSelect(
									item.customer_id,
									item.customer_nickname,
									item.customer_title,
								)
							"
						>
							Select
						</b-button>
						<b>{{ item.customer_id }}</b> &nbsp;-&nbsp;
						{{ item.customer_nickname }} &nbsp;-&nbsp;
						{{ item.customer_org_num }}
						&nbsp;-&nbsp; <span class="store"><b>Discount:</b></span
						>&nbsp;{{ item.customer_discount_percent }}% &nbsp;
						&nbsp;&nbsp;-&nbsp;<span class="alarm"
							><b>{{ item.customer_title }}</b></span
						>
					</li>
				</ul>
			</div>
		</section>
	</div>
</template>

<script>
import Store from '@/store'
import { computed, reactive, ref } from '@vue/composition-api'
import { useQuery, useResult } from '@vue/apollo-composable'
import searchVarCustomer from '@/graphql/queries/customer/searchVarCustomer.query.gql'

export default {
	name: 'PriceListSelectCustomer',
	setup() {
		const modelPriceListSelectCustomer = reactive({
			searchStringCustomer: '',
		})
		const schemaPriceListSelectCustomer = reactive({
			groups: [
				{
					legend: 'Customer Selection',
					featured: true,
					fields: [
						{
							type: 'input',
							inputType: 'text',
							label: 'Search',
							model: 'searchStringCustomer',
							id: 'search',
							readonly: false,
							featured: true,
							disabled: false,
							required: true,
							onValidated: function (model, errors) {
								if (errors.length > 0)
									console.log(
										'Validation error in Search field! Errors:',
										errors,
									)
								else {
									localStorage.setItem(
										'searchStringCustomer',
										modelPriceListSelectCustomer.searchStringCustomer,
									)
								}
							},
						},
					],
				},
			],
		})
		const formOptionsPriceListSelectCustomer = reactive({
			validateAfterLoad: false,
			validateAfterChanged: true,
			validateAsync: true,
			fieldIdPrefix: 'plcustomersearch',
		})
		const companyId = ref(Store.getters.getUserCurrentCompany)
		const searchText = computed(
			() => '%' + modelPriceListSelectCustomer.searchStringCustomer + '%',
		)
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result, loading } = useQuery(
			searchVarCustomer,
			() => ({
				company_id: companyId.value,
				search_text: searchText.value,
			}),
			options,
		)
		const searchedCustomers = useResult(result, null, (data) => data.customers)
		// On Customer selected
		Store.dispatch('setPLCurrentCompany', Store.getters.getUserCurrentCompany)
		const onSelect = (id, nickname, title) => {
			Store.dispatch('setPLSelectedCustomerID', id)
			Store.dispatch('setPLSelectedCustomerNickname', nickname)
			Store.dispatch('setPLSelectedCustomerTitle', title)
			// ---------------------------------------------------------
			Store.dispatch('setPLSearchBoxShow', false)
			Store.dispatch('setPLSearchResultsShow', false)
			Store.dispatch('setPLPriceListHeadersShow', true)
			Store.dispatch('setPLChangeCustomerButtonShow', true)
			// ---------------------------------------------------------
			// Store.dispatch(
			//   'setKeySelectCustomer',
			//   Store.getters.getKeyPriceListSelectCustomer + 1
			// )
			// ---------------------------------------------------------
			modelPriceListSelectCustomer.searchStringCustomer = ''
		}
		// -----------------------------------------------------------------------------------
		const onDefault = () => {
			Store.dispatch('setPLSearchBoxShow', true)
			Store.dispatch('setPLSearchResultsShow', true)
			Store.dispatch('setPLPriceListHeadersShow', false)
			Store.dispatch('setPLChangeCustomerButtonShow', false)
			Store.dispatch('setPLSearchStockItemShow', false)
			Store.dispatch('setPLCustomersPriceShow', false)
			Store.dispatch('setPLCustomersPriceLinesShow', false)
		}
		const onClose = () => {
			onDefault()
		}
		onDefault()
		return {
			schemaPriceListSelectCustomer,
			modelPriceListSelectCustomer,
			formOptionsPriceListSelectCustomer,
			companyId,
			searchText,
			result,
			searchedCustomers,
			loading,
			onSelect,
			onClose,
		}
	},
}
</script>

<style scoped>
.list-item {
	margin-top: 10px;
}
.small-btn {
	margin: 0 11px 0 0;
}
.alarm {
	color: red;
}
.store {
	color: green;
}
</style>
