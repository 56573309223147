<template>
	<layout-default-new>
		<div>
			<sub-navbar page="Customer" />
		</div>
		<div>
			<div>
				<price-list-select-customer />
			</div>
			<div>
				<price-list-headers />
			</div>
			<div
				v-if="$store.getters.getPLCustomersPriceLinesShow"
				:key="$store.getters.getPLCustomersPriceLinesKey"
			>
				<span class="tag is-primary is-medium"
					>CUSTOMER'S SPECIAL PRICES LIST</span
				>
				<price-list-lines />
			</div>
			<div
				v-if="$store.getters.getPLCustomersPriceShow"
				:key="$store.getters.getPLCustomersPriceKey"
			>
				<span class="tag is-primary is-medium">ADD CUSTOMER PRICE</span>
				<price-list-add-new-price />
			</div>
			<div>
				<price-list-search-stock-item />
			</div>
		</div>
	</layout-default-new>
</template>

<script>
import PriceListSelectCustomer from '@/views/customer/layouts/customerPriceList/PriceListSelectCustomer'
import PriceListHeaders from '@/views/customer/layouts/customerPriceList/priceListHeaders'
import PriceListSearchStockItem from '@/views/customer/layouts/customerPriceList/PriceListSearchStockItem'
import PriceListAddNewPrice from '@/views/customer/layouts/customerPriceList/PriceListAddNewPrice'
import PriceListLines from '@/views/customer/layouts/customerPriceList/PriceListLines'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'

export default {
	name: 'customerPriceListMainLayout',
	components: {
		PriceListSelectCustomer,
		PriceListHeaders,
		PriceListSearchStockItem,
		PriceListAddNewPrice,
		PriceListLines,
		SubNavbar,
	},
}
</script>

<style scoped>
div {
	margin-bottom: 15px;
}
</style>
