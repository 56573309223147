<template>
	<div
		v-if="$store.getters.getPLSearchStockItemShow"
		class="margin-top-30"
		style="margin-top: 60px"
	>
		<div>
			<span class="tag is-primary is-medium">SEARCH STOCK ITEM</span>
		</div>
		<section>
			<form id="PLSearchStockItem">
				<vue-form-generator
					:schema="schemaPLSearchStockItem"
					:model="modelPLSearchStockItem"
					:options="formOptionsPLSearchStockItem"
				>
				</vue-form-generator>
			</form>
			<b-button class="is-primary" type="submit" @click="onSubmit()"
				>Get all
			</b-button>
			<div class="list-item">
				<b>Search Results</b>
			</div>
			<div v-if="modelPLSearchStockItem.searchString === ''">
				Nothing to show please enter search criteria!
			</div>
			<div>
				<ul v-if="modelPLSearchStockItem.searchString !== ''">
					<li
						v-for="item of searchedStockItems"
						:key="item.stock_id"
						class="list-item"
					>
						<b-button
							class="is-primary small-btn"
							outlined
							size="is-small"
							@click="
								onSelect(item.stock_id, item.stock_name, item.stock_price)
							"
						>
							Select
						</b-button>
						<b>{{ item.stock_id }}</b> &nbsp;-&nbsp;
						{{ item.stock_name }} &nbsp;-&nbsp;
						{{ String(parseFloat(item.stock_price) / 100).replace('.', ',') }}
						&nbsp;kr.&nbsp;/&nbsp; <span class="store"><b>Store:</b></span
						>&nbsp;{{ item.stock_amount }}&nbsp;
						<span class="alarm"
							><b>{{ item.alarm_message }}</b></span
						>
					</li>
				</ul>
			</div>
		</section>
	</div>
</template>

<script>
import Store from '@/store'
import { computed, reactive, ref } from '@vue/composition-api'
import { useQuery, useResult } from '@vue/apollo-composable'
import searchVarStockItems from '../../../../graphql/queries/stock/searchVarStockItems.query.gql'

export default {
	name: 'PriceListSearchStockItem',
	setup() {
		const modelPLSearchStockItem = reactive({
			searchString: '',
		})
		const schemaPLSearchStockItem = reactive({
			groups: [
				{
					legend: 'Stock Item Search',
					featured: true,
					fields: [
						{
							type: 'input',
							inputType: 'text',
							label: 'Search',
							model: 'searchString',
							id: 'search',
							readonly: false,
							featured: true,
							disabled: false,
							required: true,
							onValidated: function (model, errors) {
								if (errors.length > 0)
									console.log(
										'Validation error in Search field! Errors:',
										errors,
									)
								else {
									localStorage.setItem(
										'searchString',
										modelPLSearchStockItem.searchString,
									)
								}
							},
						},
					],
				},
			],
		})
		const formOptionsPLSearchStockItem = reactive({
			validateAfterLoad: false,
			validateAfterChanged: true,
			validateAsync: true,
			fieldIdPrefix: 'plstockitemsearch',
		})
		const companyId = ref(Store.getters.getUserCurrentCompany)
		const searchText = computed(
			() => '%' + modelPLSearchStockItem.searchString + '%',
		)
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result, loading } = useQuery(
			searchVarStockItems,
			() => ({
				company_id: companyId.value,
				search_text: searchText.value,
			}),
			options,
		)
		const searchedStockItems = useResult(result, null, (data) => data.stock)
		const onSubmit = () => {
			modelPLSearchStockItem.searchString = '%'
		}
		// ------------------------------------
		const onSelect = (id, name, price) => {
			const rawPrice = String(parseFloat(price) / 100)
			const correctedPrice = rawPrice.replace('.', ',')
			Store.dispatch('setPLStockItemID', id)
			Store.dispatch('setPLStockItemName', name)
			Store.dispatch('setPLStockItemPrice', correctedPrice)
			Store.dispatch('setPLSearchStockItemShow', false)
			Store.dispatch('setKeyStockReload', Store.getters.getKeyStockReload + 1)
			// Store.dispatch('setPLCustomersPriceLinesShow', true)
			Store.dispatch('setPLCustomersPriceShow', true).then(() => {
				Store.dispatch(
					'setPLCustomersPriceKey',
					Store.getters.getPLCustomersPriceKey + 1,
				)
				Store.dispatch(
					'setPLCustomersPriceLinesKey',
					Store.getters.getPLCustomersPriceLinesKey + 1,
				)
			})

			// ---------------------------------------------------------
			Store.dispatch('setShowSearchForm', false)
			modelPLSearchStockItem.searchString = ''
		}
		// -----------------------------------------------------------------------------------
		return {
			schemaPLSearchStockItem,
			modelPLSearchStockItem,
			formOptionsPLSearchStockItem,
			companyId,
			searchText,
			result,
			searchedStockItems,
			loading,
			onSubmit,
			onSelect,
		}
	},
}
</script>

<style scoped>
.list-item {
	margin-top: 10px;
}
.small-btn {
	margin: 0 11px 0 0;
}
.alarm {
	color: red;
}
.store {
	color: green;
}
</style>
